<template>
  <div>
    <div v-if="label || $slots.secondary" class="flex items-end" :class="label ? 'justify-between' : 'justify-end'">
      <label v-if="label" :for="name" :class="$cn('block text-xs font-medium text-supplement', ui?.label)">{{ label }}</label>
      <slot name="secondary" />
    </div>
    <div :class="{ 'mt-2': label || $slots.secondary }">
      <slot />
    </div>
    <div v-if="description || errors" class="mt-2.5 space-y-1.5">
      <div v-show="errors" class="text-xs text-red-600">{{ errors }}</div>
      <div v-show="description" class="text-xs text-supplement">{{ description }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ClassValue } from "clsx";

const props = defineProps<{
  name: string;
  label?: string;
  description?: string;
  ui?: Partial<{
    label: ClassValue;
  }>;
}>();

const { getFieldErrors } = injectStrict<ProvideForm>("form");
const errors = getFieldErrors(props.name);
</script>
